import { PAGE_SIZE } from '@fi/constants';
import { formatStringToSnakeCase } from '@fi/util/formatStringToSnakeCase';

export function createPageSizeQueryObject() {
  return {
    pageSize: PAGE_SIZE,
  };
}

export function createPageQueryObject(searchQuery: any) {
  return {
    page: searchQuery.currentPage - 1,
  };
}

export function createSortQueryObject(searchQuery: any) {
  return searchQuery.selectedSortOption ? { orderBy: [searchQuery.selectedSortOption.value] } : {};
}

export function createAdvancedSearchQueryObject(searchQuery: any) {
  const query: any = {};
  const urlParams = new URLSearchParams(window.location.search);
  const advancedSearchObj = searchQuery.advanceSearch;

  if (advancedSearchObj) {
    Object.keys(advancedSearchObj).forEach((key) => {
      if (advancedSearchObj[key].val) {
        query[`${key}Query`] = advancedSearchObj[key].val;
      }
    });
  }

  urlParams.forEach((val, key) => {
    if (key === 'anyQuery' || key === 'allQuery' || key === 'noneQuery' || key === 'optionalQuery' || key === 'basicQuery') {
      query[key] = val;
    }
  });

  return query;
}

export function createDisciplinesQueryObject(searchQuery: any, options: any) {
  if (!searchQuery.researchAreas) {
    return {};
  }

  let asjcCategories: number[] = [];
  const { parentMap, childMap } = searchQuery.researchAreas;
  const disciplines = options.researchAreas;

  Object.keys(parentMap).forEach((key) => {
    if (parentMap[key] && disciplines) {
      const area = disciplines.items.find((item: any) => item.parent.code === key);
      asjcCategories = asjcCategories.concat(area.children.map((child: any) => child.code));
    }
  });

  const validSubDisciplineItems = Object.keys(childMap).filter((key) => Boolean(childMap[key]));
  const subDisciplines = validSubDisciplineItems.map((key) => childMap[key].code);
  asjcCategories = asjcCategories.concat(subDisciplines);

  if (asjcCategories.length === 0) {
    return {};
  }

  return {
    asjcCategories: asjcCategories,
  };
}

export function createListQueryObject(itemKey: string, paramName: string, keyName = 'key', valueToSnakeCase = false, encodeString = false) {
  return (searchQuery: any) => {
    const items = searchQuery[itemKey];

    if (!items || !items.selectedItems.length) {
      return {};
    }

    const values = items.selectedItems.map((item: any) => {
      let value = item[keyName];
      if (valueToSnakeCase) {
        value = formatStringToSnakeCase(value);
      } else if (encodeString) {
        value = encodeURIComponent(value);
      }
      return value;
    });

    return {
      [paramName]: values,
    };
  };
}

export function createAmountQueryObject(searchQuery: any) {
  const amount = searchQuery.amount;

  if (!amount) {
    return {};
  }

  const queryObject: any = {};

  if (amount.max) {
    queryObject['amountMaximum'] = amount.max;
  }
  if (amount.min) {
    queryObject['amountMinimum'] = amount.min;
  }
  if (amount.currency) {
    queryObject['currency'] = amount.currency;
  }
  return queryObject;
}

export function createBooleanQueryObject(propName: string, paramName: string) {
  return (searchQuery: any) => {
    return searchQuery[propName] ? { [paramName]: true } : {};
  };
}
export function createStringQueryObject(paramName: string) {
  return (searchQuery: any) => {
    const searchParam = searchQuery[paramName];
    return searchParam ? { [paramName]: searchParam } : null;
  };
}
